import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  sortableKeyboardCoordinates,
  arrayMove,
} from '@dnd-kit/sortable';
import { useQueryClient } from '@tanstack/react-query';
import { restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import useKeyPress, { ESCAPE_KEY } from '../../../hooks/useKeyPress';
import * as CustomerState from '../../../store/recoil/customers';
import { ICmdTableColumn, ICmdTableSettingsData, ICmdTableSortFilter } from '../interfaces';
import { ACTIONS_KEY, CUSTOMERS_KEY } from '../constants';
import * as CmdTableState from '../CmdTableState';
import { SortableItem } from './SortableItem';
import { arrayOrder, saveTableSettings } from '../CmdTableService';

interface ICmdTableSettingsMenuProps {
  tableId: string;
  actionColumnEnabled?: boolean;
  sort?: {
    column: string;
    direction: string;
  };
  tableName: string;
  translationKeys: { [key: string]: string };
}
const WORDS_LIMIT = 20;

function CmdTableSettingsMenu(props: ICmdTableSettingsMenuProps): JSX.Element {
  const [columnsTable, setColumnsTable] = useRecoilState<ICmdTableColumn[]>(CmdTableState.columns);
  const [filteredColumns, setFilteredColumns] = React.useState<ICmdTableColumn[]>(
    columnsTable ? columnsTable.filter((i) => i.key !== ACTIONS_KEY && !i.system) : []
  );
  const setOrderForColumns = useSetRecoilState(CmdTableState.columnsKeyOrder);
  const { formatMessage: f } = useIntl();
  const setIsTableSettingsMenuOpened = useSetRecoilState(CmdTableState.isTableSettingsMenuOpened);
  const [isResetBtnDisable, setIsResetBtnDisable] = React.useState<boolean>(true);
  const [prevColumns, setPrevColumns] = React.useState<ICmdTableColumn[]>([]);
  const tableSettingsState = useRecoilValue<ICmdTableSettingsData | null>(
    CmdTableState.tableSettingsState
  );
  const sortFilter = useRecoilValue<ICmdTableSortFilter>(CmdTableState.sortFilter(props.tableId));
  const setTableSettingsState = useSetRecoilState<ICmdTableSettingsData | null>(
    CmdTableState.tableSettingsState
  );
  const customer = useRecoilValue(CustomerState.customers);
  // const filterForRequest = useRecoilValue<ICmdTableFilter>(CmdTableState.filterForRequest);
  const queryClient = useQueryClient();
  const tableSettings = queryClient.getQueryData(['cmdTableSettings']);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    const oldIndex = filteredColumns.findIndex((item) => item.key === active.id);
    const newIndex = filteredColumns.findIndex((item) => item.key === over.id);

    let newColumns = [...filteredColumns];

    if (oldIndex === newIndex) {
      const column = { ...newColumns[oldIndex] };
      if (!column.default) {
        column.hidden = !column.hidden;
        newColumns[oldIndex] = column;
      }
    } else {
      newColumns = arrayMove(newColumns, oldIndex, newIndex);
    }

    setFilteredColumns(newColumns);
  };

  useKeyPress(ESCAPE_KEY, () => {
    setIsTableSettingsMenuOpened(false);
  });

  const handleTableSettings = async (data: ICmdTableColumn[]) => {
    setIsTableSettingsMenuOpened(false);
    const tempOrder = data.map((item) => item.key);
    const tempData: { name: string; hidden: boolean }[] = [];
    const tempColumns: { key: string; hidden: boolean; default: boolean }[] = [];

    data.map((item: { key: string; hidden: boolean; default: boolean }) => {
      tempData.push({ name: item.key, hidden: item.hidden });
      tempColumns.push({ key: item.key, hidden: item.hidden, default: item.default });
    });

    // tempColumns.push({ key: ACTIONS_KEY, hidden: false, default: false });

    const orderColumns = arrayOrder([...tempColumns], [...tempOrder], 'key');

    setColumnsTable(orderColumns);

    const sortFilterColumn = Object.keys(sortFilter)[0];
    const sortFilterOrder = sortFilter[sortFilterColumn];

    const dataForRequest = {
      ...tableSettingsState,
      columns: [...tempData],
      sort: {
        column: sortFilterColumn,
        direction: sortFilterOrder,
      },
    };
    if (props.sort) {
      dataForRequest.sort = props.sort;
    }
    const saveSettings = await saveTableSettings(dataForRequest, props.tableName);
    if (saveSettings) {
      setTableSettingsState(saveSettings.value);
    }
  };

  // const arrayMoveMutable = (array: ICmdTableColumn[], fromIndex: number, toIndex: number) => {
  //   const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  //   if (startIndex >= 0 && startIndex < array.length) {
  //     const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

  //     const [item] = array.splice(fromIndex, 1);
  //     array.splice(endIndex, 0, item);
  //   }
  // };

  const handleApply = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    const order: { key: string; hidden: boolean }[] = [];

    filteredColumns.map((item) => {
      order.push({ key: item.key, hidden: item.hidden });
    });
    if (props.actionColumnEnabled) {
      // order.push({ key: ACTIONS_KEY, hidden: false });
    }

    setOrderForColumns(order);
    handleTableSettings(filteredColumns);
  };

  const handleDefaultColumns = (columns: ICmdTableColumn[]) => {
    const sortedArray = [
      ...columns.filter((item) => item.key !== ACTIONS_KEY),
      // ...columns.filter((item) => item.key === ACTIONS_KEY),
    ];

    setFilteredColumns(sortedArray ? sortedArray.filter((i) => i.key !== ACTIONS_KEY) : []);
  };

  const handleReset = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    const tempTableSettingOrder: ICmdTableColumn[] = [];

    columnsTable.map((item) => {
      if (customer.length === 1 && item.key === CUSTOMERS_KEY) {
        tempTableSettingOrder.push({ key: item.key, default: item.default, hidden: true });
      } else {
        tempTableSettingOrder.push({ key: item.key, default: item.default, hidden: item.hidden });
      }
    });

    setOrderForColumns(columnsTable);
    handleDefaultColumns(columnsTable);
  };

  React.useEffect(() => {
    if (JSON.stringify(filteredColumns) !== JSON.stringify(prevColumns)) {
      if (prevColumns.length > 0) {
        setIsResetBtnDisable(false);
      }
      setPrevColumns(filteredColumns);
    }
  }, [filteredColumns, prevColumns]);

  const handleLongWords = (word: string) => {
    if (word.length > WORDS_LIMIT) {
      return `${word.slice(0, WORDS_LIMIT)}...`;
    }
    return word;
  };
  const handleTooltipWhenLong = (word: string) => {
    if (word.length > WORDS_LIMIT) {
      return word;
    }
    return '';
  };

  const getItemTitle = (key: string) => {
    if (props.translationKeys?.[key]) {
      return f({ id: `${props.translationKeys[key]}` });
    }
    return 'No translation key';
  };

  return (
    <div className="col-auto mr-3">
      <div className="b-dropdown open" style={{ width: '220px', marginLeft: '-14px' }}>
        <div
          className="b-dropdown__content"
          style={{ position: 'absolute', zIndex: 999, overflow: 'hidden' }}
        >
          {/* <div className="b-dropdown__title b-dropdown__title--border-0">
            {f({ id: 'tables.tripsOverview.tableSettings.header' })}
          </div> */}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToParentElement, restrictToFirstScrollableAncestor]}
          >
            <SortableContext
              items={filteredColumns.map((item) => item.key)}
              strategy={verticalListSortingStrategy}
            >
              <ul
                className="b-dropdown__list"
                style={{
                  overflowY: 'auto',
                  maxHeight: 'calc(100vh - 260px)',
                  whiteSpace: 'nowrap',
                  overflowX: 'hidden',
                  maxWidth: '220px',
                }}
              >
                {filteredColumns.map((item) => (
                  <SortableItem key={item.key} item={item}>
                    <i style={{ margin: '0 -2px 0 -4px' }} className="cmd-icons cmd-icon-reorder" />
                    <div
                      className="e-checkbox"
                      title={handleTooltipWhenLong(getItemTitle(item.key))}
                    >
                      <label>
                        <input
                          type="checkbox"
                          checked={!item.hidden}
                          disabled={!!item.default}
                          data-cy={`tlclite-settings-checkbox-${item.key}`}
                        />
                        <span style={{ fontSize: '13px' }}>
                          {handleLongWords(getItemTitle(item.key))}
                        </span>
                      </label>
                    </div>
                  </SortableItem>
                ))}
              </ul>
            </SortableContext>
          </DndContext>
          <div className="b-dropdown__footer b-dropdown__footer--border-on">
            <a
              onClick={handleReset}
              href="#"
              className={`${
                isResetBtnDisable ? 'e-button e-button--gray e-button--disabled' : 'e-button'
              }`}
              data-cy="tlclite-settings-reset"
            >
              {f({ id: 'form.cancel.tooltip' })}
            </a>
            <a
              onClick={handleApply}
              href="#"
              className="e-button e-button--gray"
              data-cy="tlclite-settings-apply"
            >
              {f({ id: 'table.apply' })}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CmdTableSettingsMenu;
